import {
    BetSlipProps
    // IGetBetHistory
} from 'types/bet';
import axios from './axios';

export const getSportHomeData = async () => {
    const res = await axios.get('api/sports/get-home');
    return res.data;
};

export const getSportUpcommingData = async () => {
    const res = await axios.get('api/sports/get-upcomming');
    return res.data;
};

export const getInplayData = async () => {
    const res = await axios.get('api/sports/get-inplay');
    return res.data;
};

export const getMarket = async (params: { marketId: string }) => {
    const res = await axios.post('api/sports/getMarket', params);
    return res.data;
};

export const getOpenBets = async (params: { marketId: string }) => {
    const res = await axios.post('api/sports/getOpenBets', params);
    return res.data;
};

export const playerBet = async (params: BetSlipProps) => {
    const res = await axios.post('api/sports/playerBet', params);
    return res.data;
};

// export const getBetHistory = async (params: IGetBetHistory) => {
//     const res = await axios.post('api/sports/get-bet-history', params);
//     return res.data;
// };

export const getBetHistory = async (params) => {
    const res = await axios.post('api/users/get-bet-history', params);
    return res.data;
};

export const getAccountStatement = async (params) => {
    const res = await axios.post(`api/users/accountStatement`, params);
    return res.data;
};

export const getEventPL = async (params) => {
    const res = await axios.post(`api/users/get-event-pl`, params);
    return res.data;
};

export const getMarketPL = async (params) => {
    const res = await axios.post(`api/users/getMarketPL`, params);
    return res.data;
};

export const getEventSetting = async (params: { marketId: string }) => {
    const res = await axios.post(`api/sports/exchange/setting`, params);
    return res.data;
};

export const getSettingData = async () => {
    const res = await axios.get(`api/settings`);
    return res.data;
};

export const getParentsIdByOrder = async () => {
    const res = await axios.get(`api/users/getParentsIds`);
    return res.data;
};

export const getPromotions = async () => {
    const res = await axios.get(`api/bonus`);
    return res.data;
};

export const getBonusList = async () => {
    const res = await axios.post(`api/bonus/getUserBonus`);
    return res.data;
};

export const cancelBonus = async (id: string) => {
    const res = await axios.post(`api/bonus/cancel`, { id });
    return res.data;
};

export const claimBonus = async (id: string) => {
    const res = await axios.post(`api/bonus/claim`, { id });
    return res.data;
};

export const getSt8Games = async (provider: string) => {
    const res = await axios.post(`api/st8/get-games`, { provider });
    return res.data;
};

export const searchSt8Games = async (search: string) => {
    const res = await axios.post(`api/st8/search-games`, { search });
    return res.data;
};

export const gameLaunch = async (params: any) => {
    const res = await axios.post(`api/st8/launch`, params);
    return res.data;
};

export const getManualPayments = async () => {
    const res = await axios.get(`api/manual-payment`);
    return res.data;
};

export const updateUserStake = async (param: any) => {
    const res = await axios.post(`api/settings/update-user-stake`, param);
    return res.data;
};

export const fPayDeposit = async (param: any) => {
    const res = await axios.post(`api/fpay/order`, param);
    return res.data;
};

export const fPayWithdraw = async (param: any) => {
    const res = await axios.post(`api/fpay/order`, param);
    return res.data;
};

export const createDeposit = async (param: any) => {
    const res = await axios.post(`api/payment/deposit`, param, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
    return res.data;
};

export const createWithdraw = async (param: any) => {
    const res = await axios.post(`api/payment/withdraw`, param);
    return res.data;
};

export const kycVerify = async (param: any) => {
    const res = await axios.post(`api/kyc`, param, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
    return res.data;
};

export const updateKyc = async (param: any, id: string) => {
    const res = await axios.put(`api/kyc/${id}`, param, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
    return res.data;
};

export const getKyc = async () => {
    const res = await axios.get(`api/kyc/mine`);
    return res.data;
};

export const createBankCredential = async (params: any) => {
    const res = await axios.post(`api/withdraw-bank`, params);
    return res.data;
};

export const updateBankCredential = async (params: any, id: string) => {
    const res = await axios.put(`api/withdraw-bank/${id}`, params);
    return res.data;
};

export const getBankCredentials = async () => {
    const res = await axios.get(`api/withdraw-bank`);
    return res.data;
};

export const deleteBankCredential = async (id: string) => {
    const res = await axios.delete(`api/withdraw-bank/${id}`);
    return res.data;
};

export const getPendingDeposit = async () => {
    const res = await axios.get(`api/payment/get-pending-deposit`);
    return res.data;
};

export const getPendingWithdraw = async () => {
    const res = await axios.get(`api/payment/get-pending-withdraw`);
    return res.data;
};

export const getDepositHistory = async (params: any) => {
    const res = await axios.post(`api/payment/get-deposit-history`, params);
    return res.data;
};

export const getWithdrawHistory = async (params: any) => {
    const res = await axios.post(`api/payment/get-withdraw-history`, params);
    return res.data;
};

export const getLoginHistory = async (params: any) => {
    const res = await axios.post(`api/users/getLoginHistory`, params);
    return res.data;
};

// auth
export const updatePassword = async (params: any) => {
    const res = await axios.post(`api/auth/change-password`, params);
    return res.data;
};

export const registerCheck = async (params: any) => {
    const res = await axios.post(`api/auth/register-check`, params);
    return res.data;
};

export const sendOTP = async (params: any) => {
    const res = await axios.post(`api/auth/send-otp`, params);
    return res.data;
};

export const sendPasswordOTP = async (params: any) => {
    const res = await axios.post(`api/auth/send-password-otp`, params);
    return res.data;
};

export const reSendOTP = async (params: any) => {
    const res = await axios.post(`api/auth/resend-otp`, params);
    return res.data;
};

export const verifyOTP = async (params: any) => {
    const res = await axios.post(`api/auth/register-otp`, params);
    return res.data;
};

export const getSt8Categories = async () => {
    const res = await axios.get(`api/st8/get-categories`);
    return res.data;
};

export const getSportCategory = async (params) => {
    const res = await axios.post(`api/sports/get-category`, params);
    return res.data;
};

export const resetPassword = async (params) => {
    const res = await axios.post(`api/auth/set-password`, params);
    return res.data;
};

export const cashout = async (params) => {
    const res = await axios.post(`api/sports/cashout`, params);
    return res.data;
};
